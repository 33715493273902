import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"
import { Carousel } from "react-bootstrap";
import LoadingSpinner from "../components/loading-spinner"

//var d3 = require('d3');
//var topojson = require('topojson');

export default () => {

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "developers")
  },[]);



  function carousel(){

    const text_list = [
      "glamping site",
      "drivethru kiosk",
      "beach resort",
      "infill development",
      "glamping site",
      "drivethru kiosk",
      "beach resort",
      "infill development",
      "glamping site",
      "drivethru kiosk",
      "beach resort",
      "infill development",
      "glamping site",
      "drivethru kiosk",
      "beach resort",
      "infill development",
      "glamping site",
      "drivethru kiosk",
      "beach resort",
      "infill development"];
  
      text_list.forEach(function(item, i) {
        
          setTimeout(
            function(){
              try {
                  document.getElementById("carousel_text").innerHTML = item;
                  } catch (e) {  }
            }, i * 1500); 
            
        });
    }
  
    carousel();

    function submitContactForm(form_type){

      //SHOW SPINNER
      document.getElementById("spinner-business").className = ""; 
  
      var uuid = require("uuid");
      var request_id = uuid.v4();
  
      var first_name = document.getElementById("first_name_business").value;
      var last_name = document.getElementById("last_name_business").value;
      var email = document.getElementById("email_business").value;
      var phone = document.getElementById("phone_business").value;
      var message = document.getElementById("message_business").value;
  
      if (!email) { return }
  
      //GET ALL ORDERS IDS
      var data = new FormData();
      data.append("request_id", request_id);
      data.append("form_type", form_type);
      data.append("first_name", first_name);
      data.append("last_name", last_name);
      data.append("email", email);
      data.append("phone", phone);
      data.append("message", message);
  
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.checksolarcost.com/v1/submitContactForm");
      xhr.send(data);
      xhr.onreadystatechange = function() {
  
      if (xhr.readyState === XMLHttpRequest.DONE) {
  
          //READ DATA
          var response = xhr.responseText;
          var response_obj = JSON.parse(response);
  
          if (form_type == "developers_request") {
              document.getElementById("spinner-business").className = "hidden"; 
              document.getElementById("contact-business-form").className="relative flex lg:flex-row flex-col bg-gray-200 hidden";
              document.getElementById("contact-business-form-sent").className="relative flex lg:flex-row flex-col bg-gray-200"; 
          }
        }
      }
    }
  
    function scrollToReport(){
      const divElement = document.getElementById('contact-form');
      divElement.scrollIntoView({ behavior: 'smooth' });
    }
  
    //className="w-full h-auto py-12 border-b border-gray-300 outline-none focus:outline-none md:px-8 md:w-1/2 lg:px-0 lg:flex lg:items-center lg:justify-between"

    //<h1 className="w-full h-auto py-12 border-b lg:max-w-xlg m-auto pt-16 text-gray-600 tracking-wide font-light py-38 px-16">
          //Let’s build your <h4><m id="carousel_text">glamping site</m></h4>
          //</h1>

function checkSendMessageButton(){

  var first_name = document.getElementById("first_name_business").value;
  var last_name = document.getElementById("last_name_business").value;
  var email = document.getElementById("email_business").value;
  var phone = document.getElementById("phone_business").value;
  var message = document.getElementById("message_business").value;

  //alert(user_type)
  if (first_name.length > 2 && last_name.length > 2 && phone.length >= 10 && email.includes("@") == true && email.includes(".") == true && message.length > 2) { 
    document.getElementById("send-message-button-disabled").className = "hidden";
    document.getElementById("send-message-button").className = "";
  } else {
    document.getElementById("send-message-button-disabled").className = "";
    document.getElementById("send-message-button").className = "hidden";
  }


}




  return (
    <>
    <SEO title="Developers" />
    <Layout>
      <section>
        <div className="flex flex-col text-left justify-text m-auto h-full">
          <div className="xl:mx-20 lg:mx-18 py-28 px-16" >
            <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">

            <h1 className="w-full h-auto py-12 lg:max-w-xlg m-auto pt-26 text-gray-600 tracking-wide font-light py-48 px-26">
            Let’s build your <h4><m id="carousel_text">glamping site</m></h4>
            </h1>
                Whether you are developing a tropical resort in the Caribbean or a glamping camp 
                in New Mexico we have literally been there, making these projects happen with 
                developers like you. Modal is a provider of durable to premium prefabricated 
                modules with short production lead-times, lightning transportation, expert 
                site prep, and half-day installations (and we’re happy to stay out of any 
                step you prefer to handle). This increases your speed to revenue and minimizes 
                site disruptions. Prefab construction is particularly valuable in areas where 
                site-built labor costs are high, the building season is short, or the site is 
                rural/remote. Together we can develop something great. Reach out, we’re here to help!
            </p>
            <button
                onClick={() => scrollToReport()}
                //type="submit"
                className={`relative transition transition-delay-longer w-100 bg-green-900 text-white text-center flex justify-center font-bradford items-center px-12 py-3 mx-0 mt-6 shadow-xl outline-none focus:outline-none`}
              >
                  Contact Business Team
              </button>
              <br></br>
          </div>
        </div>
      </section>

      <section>
      <div id="contact-form"></div>
      <br></br>
      <br></br>
      <br></br>
      </section>
      
      <section id="contact-business-form" className="relative flex lg:flex-row flex-col bg-gray-200">
        <div className="lg:w-full py-20">
          <h3 className="text-center font-light tracking-wide">Contact Business Team</h3>
          <div className="flex justify-center items-center mt-6 px-6">
            <div className="w-full mx-auto" style={{ maxWidth: "400px" }}>
              <div className="md:grid grid-cols-2 col-gap-8 my-1">
                <div className="w-full">
                  <label htmlFor="first_name_business" className="hidden">
                    First Name
                  </label>
                  <input
                    //onChange={e => handleUserInputChange(e.target)}
                    id="first_name_business"
                    className="p-2 my-4 bg-gray-200 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                    name="first_name_business"
                    placeholder="First Name"
                    autoComplete="off"
                    onChange={() => checkSendMessageButton()}
                  />
                </div>
                <div>
                  <label htmlFor="last_name_business" className="hidden">
                    Last Name
                  </label>
                  <input
                    //onChange={e => handleUserInputChange(e.target)}
                    id="last_name_business"
                    className="p-2 my-4 bg-gray-200 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                    name="last_name_business"
                    placeholder="Last Name"
                    autoComplete="off"
                    onChange={() => checkSendMessageButton()}
                  />
                </div>
              </div>

              <label htmlFor="email_business" className="hidden">
                Email
              </label>
              <input
                //onChange={e => handleUserInputChange(e)}
                type="email"
                className="p-2 my-4 bg-gray-200 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                id="email_business"
                name="email_business"
                placeholder="Email"
                autoComplete="off"
                onChange={() => checkSendMessageButton()}
              />

              <label htmlFor="phone_business" className="hidden">
                Phone
              </label>
              <input
                //onChange={e => handleUserInputChange(e)}
                id="phone_business"
                className="p-2 my-4 bg-gray-200 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                type="tel"
                name="phone_business"
                placeholder="Phone"
                autoComplete="off"
                onChange={() => checkSendMessageButton()}
              />

              <label htmlFor="message_business" className="hidden">
                Message
              </label>
              <textarea
                //onChange={e => handleUserInputChange(e)}
                rows="3"
                placeholder="Write us a message..."
                id="message_business"
                name="message_business"
                //value={formState.message}
                className="p-2 my-4 bg-gray-200 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                onChange={() => checkSendMessageButton()}
              ></textarea>

              <div className="flex justify-center pb-10">
                <div id="send-message-button-disabled" className="">
                  <button
                    className={`relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none`}
                    disabled
                  >Send Message
                  </button>
                </div>
                <div id="send-message-button" className="hidden">
                  <button
                    onClick={() => submitContactForm("developers_request")}
                    className={`relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none`}
                  >Send Message
                  </button>
                </div>
              </div>
              <div id="spinner-business" className="hidden">
                <LoadingSpinner />
              </div>

            </div>
          </div>
        </div>
      </section>

      <section id="contact-business-form-sent" className="relative flex lg:flex-row flex-col bg-gray-200 hidden">
        <form className="lg:w-full py-40">
          <h4 className="text-center font-light tracking-wide">Message successfully sent</h4>
        </form>
      </section>

    </Layout>
    </>
  )

}