import React, { useState, useRef, useLayoutEffect } from "react"
import { document, window } from "browser-monads"

export const useScrollPercentage = () => {
  const [percentage, setPercentage] = useState(0)
  const windowHeight = useRef(window.innerHeight)
  const docTop = useRef(0)

  useLayoutEffect(() => {
    const onScroll = () => {
      const top = Math.abs(docTop.current)

      if (top >= windowHeight.current) {
        docTop.current = document.documentElement.scrollTop
        return
      }

      const percentage = top / windowHeight.current
      if (percentage < 0.2) {
        setPercentage(0)
      } else {
        setPercentage(percentage)
      }

      docTop.current = document.documentElement.scrollTop
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return [percentage, windowHeight.current]
}