import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Image from "gatsby-image"
import Logo from "./images/logo"
import { useScrollPercentage } from "../hooks/useScrollPercentage"
import ScrollDownButton from "./scroll-down-button"

export default function HeroSection({
  children,
  className = "",
  fluid,
  fill = "white",
  showScroll = true,
}) {
  const [opacity, setOpacity] = useState(0)
  const [percentage, height] = useScrollPercentage()
  useEffect(() => {
    setOpacity(percentage)
  }, [percentage])

  return (
    <section
      className={`relative h-screen w-screen overflow-hidden z-0 md:h-screen`}
    >
 
      <div
        className="fixed inset-0"
        style={{
          zIndex: "-2",
        }}
      >
        {fluid ? (
          <Image
            style={{ zIndex: "-1" }}
            className="h-screen min-w-full"
            objectFit="cover"
            fluid={fluid}
          ></Image>
        ) : null}

        <div
          style={{
            opacity,
            zIndex: "-1",
            transition: "opacity 200ms ease-out",
          }}
          className="fixed inset-0 bg-black"
        ></div>
      </div>

      <div className={`fixed inset-0 ${className}`}>{children}</div>

      <div
        style={{
          opacity: showScroll ? 1 : 0,
          marginBottom: "110px",
        }}
      >
        <ScrollDownButton scrollTo={height}></ScrollDownButton>
      </div>
    </section>
  )
}

HeroSection.propTypes = {
  className: PropTypes.string,
}
