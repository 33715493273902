import React, { useEffect, useState } from "react"
import { window } from "browser-monads"

import ArrowScroll from "./images/arrow-scroll"
import "./scroll-down-button.css"

export default function ScrollDownButton({ scrollTo }) {

  const [handleClick] = useState(0)

  useEffect(() => {   
    const handleClick = () => {
      window.scrollTo({
        top: scrollTo,
        left: 0,
        behavior: "smooth",
      })
    }
  },[]);

  return (
    <button
      onClick={handleClick}
      className="absolute bottom-0 scroll-icon z-10 cursor-pointer focus:outline-none outline-none fill-current"
      style={{
        bottom: "0px",
        left: "50%",
        transform: "translateX(-505)",
      }}
    >
      <div>
        <ArrowScroll></ArrowScroll>
      </div>
    </button>
  )
}
